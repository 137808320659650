
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import { useStore } from "vuex";
type ExamIds = {
  paperId: string,
  quId: string
}
export default defineComponent({
  name:'homeContent',
  setup(props) {
    const store = useStore();
	const contentData = reactive({
		examData: computed(() => { return store.state.examData; }),
		fontSize: computed(() => { return store.state.fontSize; }),
		questionData: computed(() => { return store.state.questionData }),
		questionType: computed(() => { return store.state.questionType})
	})
    const examIds = reactive<ExamIds>({
      paperId: '',
      quId: ''
    })
	// function setIds(paperId: string, quId: string): void {
	// 	if(paperId) {
	// 		examIds.paperId = paperId;
	// 	}
	// 	if(quId) {
	// 		examIds.quId = quId;
	// 		getExamData();
	// 	}
	// }
	function handPrevious(): void{
		store.dispatch('prevQuestion')
	}
	function handNext(): void{
		store.dispatch('nextQuestion')
	}
	// 获取题目详情
	// function getExamData(): void {
    //   	quDetail(examIds,false,'json',true).then(res => {
	// 		if(res.code === 0) {
	// 			contentData.examData = res.data;
	// 		} else {
	// 			ElMessage.warning(res.msg);
	// 		}
	// 	});
	// }
	// 点击单选
	function radioChange(e: any,index: number): void {
		for(let i in contentData.examData.answerList) {
			contentData.examData.answerList[i].checked = false;
		}
		contentData.examData.answerList[index].checked = true;
		store.commit('changeExamData',contentData.examData);
	}
	// 点击多选
	function checkBoxChange(e: any,index: number): void {
		store.commit('changeExamData',contentData.examData);
	}
	return {
		handPrevious,
		handNext,
		radioChange,
		checkBoxChange,
      	...toRefs(contentData),
	}
  }
})
