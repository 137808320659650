
import { defineComponent, computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup(peop, ctx) {
    const store = useStore();
    const viewData = reactive({
      examQuestionList: computed(() => {
        return store.getters.getExamQuestionList;
      }),
    });
    const sign = (index: number): void => {
      viewData.examQuestionList.radioList[index].isSign = !viewData.examQuestionList.radioList[index].isSign;
      store.commit("changeTopitData", viewData.examQuestionList);
    };
		function clickExam(item: any,index: number, type: string): void {
      if(!item.isSccomplish) {
        if(viewData.examQuestionList.radioList.length) {
          for(let i in viewData.examQuestionList.radioList) {
            viewData.examQuestionList.radioList[i].isSccomplish = false;
          } 
        }
        if(viewData.examQuestionList.multiList.length) {
          for(let i in viewData.examQuestionList.multiList) {
            viewData.examQuestionList.multiList[i].isSccomplish = false;
          } 
        }
        if(viewData.examQuestionList.judgeList.length) {
          for(let i in viewData.examQuestionList.judgeList) {
            viewData.examQuestionList.judgeList[i].isSccomplish = false;
          } 
        }
        item.isSccomplish = !item.isSccomplish;
        viewData.examQuestionList[type][index] = item;
        store.commit('changeTopitData',viewData.examQuestionList);
        store.dispatch('clickQuestionChange',{data: item,index: index, type: type})
        if(type === 'radioList') {
          if(index == 0) {
            store.commit('examIndex',{isEnd: false,isStart: true})
          } else if(index == (viewData.examQuestionList.radioList.length - 1)) {
            if(!viewData.examQuestionList.multiList.length) {
              if(!viewData.examQuestionList.judgeList.length) {
                store.commit('examIndex',{isEnd: true,isStart: false})
              }
            }
          } else {
            store.commit('examIndex',{isEnd: false,isStart: false})
          }
        } else if(type === 'multiList') {
          if(index == 0) {
            store.commit('examIndex',{isEnd: false,isStart: false})
          } else if(index == (viewData.examQuestionList.multiList.length - 1)) {
            if(!viewData.examQuestionList.judgeList.length) {
              store.commit('examIndex',{isEnd: true,isStart: false})
            }
          } else {
            store.commit('examIndex',{isEnd: false,isStart: false})
          }
        } else if(type === 'judgeList') {
          if(index == (viewData.examQuestionList.judgeList.length - 1)) {
            store.commit('examIndex',{isEnd: true,isStart: false})
          } else {
            store.commit('examIndex',{isEnd: false,isStart: false})
          }
        }
        // store.commit('changeQuId',item.quId);
        // store.commit('changeTopicIndex',item.sort);
      }
		}
    const examItemStyle = function(item: any): string {
      let str = '';
      if(item.isSccomplish) {
        str += 'color: #FFFFFF;background: #4E202E;'
      } else {
        if(item.answered) {
          str += 'color: #FFFFFF;background: #96D098';
        } else {
          str += 'color: #333333;background: #F3F3F9';
        }
      }
      return str;
    };
    const sign2 = (index: number): void => {
      viewData.examQuestionList.multiList[index].isSign = !viewData.examQuestionList.multiList[index].isSign;
      store.commit("changeTopitData", viewData.examQuestionList);
    };
    const sign3 = (index: number): void => {
      viewData.examQuestionList.judgeList[index].isSign = !viewData.examQuestionList.judgeList[index].isSign;
      store.commit("changeTopitData", viewData.examQuestionList);
    };
    return {
      ...toRefs(viewData),
      examItemStyle,
      sign,
      sign2,
      sign3,
			clickExam,
    };
  },
});
